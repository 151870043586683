<template>
  <div>
    <v-menu
      v-model="show"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
      :disabled="disabled || readonly"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formattedDate"
          :label="label"
          :hide-details="noHideDetails ? false : true"
          :append-icon="icon || 'mdi-calendar-blank'"
          readonly
          @blur="$emit('blur')"
          v-on="on"
          :outlined="outlined"
          :clearable="!readonly && clearable"
          :error="error"
          :error-message="errorMessage"
          :error-messages="errorMessages"
          :disabled="disabled"
        >
          <template v-slot:append>
            <slot name="append" />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        ref="picker"
        v-model="selectedDate"
        no-title
        color="primary"
        @input="show = false"
        @blur="$emit('blur')"
        :allowed-dates="allowDates"
        :locale="i18n.locale"        
        :min="firstAvailable ? moment(firstAvailable).subtract(1, 'd').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null"
        :max="lastAvailable ? moment(lastAvailable).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null"
        :first-day-of-week="dayjs().locale('es').weekday(0).day()"
      />
    </v-menu>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import weekdayPlugin from 'dayjs/plugin/weekday'
import { i18n } from '@/locale'
import 'dayjs/locale/es'
dayjs.extend(weekdayPlugin)
export default {
  props: {
    label: String,
    icon: String,
    initialValue: String,
    readonly: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    dateOfBirth: Boolean,
    error: Boolean,
    noHideDetails: Boolean,
    errorMessage: String,
    errorMessages: Array,
    omitInitialChange: Boolean,
    allowDates: Function,
    emitSameAsInitialValue: Boolean,
    outlined: Boolean,
    firstAvailable: String,
    lastAvailable: String
  },
  data () {
    return {
      dayjs,
      i18n,
      selectedDate: null,
      show: false
    }
  },
  created () {
    if (this.initialValue && !this.omitInitialChange) {
      this.selectedDate = this.initialValue
    }
  },
  computed: {
    formattedDate: {
      get () {
        return this.selectedDate
          ? dayjs(this.selectedDate).format('DD/MM/YYYY')
          : null
      },
      set (value) {
        this.selectedDate = value
      }
    }
  },
  methods: {
    clear () {
      this.selectedDate = null
    }
  },
  watch: {
    selectedDate (value) {
      if (!this.initialValue || (this.initialValue && value !== dayjs(this.initialValue).format('YYYY-MM-DD')) || this.emitSameAsInitialValue) {
        this.$emit('change', value ? dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null)
      }
    },
    show (val) {
      if (this.dateOfBirth) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      }
    },
    initialValue: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.selectedDate = dayjs(val).format('YYYY-MM-DD')
        }
      }
    }
  }
}
</script>
